import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ControlUtils, ErrorMessageFormId, OcErrorService } from '@mbd-common-libs/angular-common-components/src/lib/common-components';
import { forIn, set, toPath } from 'lodash';
import { map, takeUntil } from 'rxjs/operators';
import { AppFormModel, defaultFieldsForTrim, FormConfigService, FormInputs, TrimTextUtils } from '@mbd-common-libs/angular-common-components';
import { CustomOcFormGenerator } from './custom-oc-form-genrator';
import { CustomOcFormValidator } from './custom-oc-form-validator';
import { Router } from '@angular/router';
@Component({
  selector: 'app-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss']
})
export class CustomFormComponent implements OnInit {

    @Input() formJsonData: AppFormModel;
  
      /**
       * Set disable for button
       * when siblings form is invalid
       */
      @Input() anotherInvalidResult = false;
  
      /**
       * Show button on form.
       * @default true
       */
      @Input() showButton: boolean = true;
  
      /**
       * Set position of the buttons.
       * Can be: "center", "left", "right".
       * @default 'left'
       */
      @Input() buttonPosition: 'center' | 'left' | 'right' = 'left';
  
      /**
       * Set custom text to success button.
       * @default 'Submit'
       */
      @Input() successButtonText: string = 'Submit';
  
      /**
       * Set form "dirty" after form init
       */
      @Input() setFormDirty: boolean = false;
  
      /**
       * Submitting process. `true` option will lock for
       *  click and start the spinner in the submit button
       */
      @Input() process: boolean = false;
  
      /**
       * Flag to show group heading
       */
      @Input() showGroupHeading: boolean = true;
  
      /**
       * Already generated Form Group
       */
      @Input() generatedForm: FormGroup;
  
      /** Current form ID. Used for modifying error messages. Look:  {@link ErrorMessageFormId} */
      @Input() formId: ErrorMessageFormId = null;
  
      /**
       * Returning all form fields value to the parent component
       */
      @Output() readonly formSubmitted = new EventEmitter<any>();
  
      /** Sending true when user cancel form submitting */
      @Output() readonly cancelSubmit: EventEmitter<boolean> = new EventEmitter<boolean>();
  
      /** When need to get data of the form without buttons */
      @Output() readonly formDataUpdated: EventEmitter<any> = new EventEmitter<any>();
  
      /** Send form valid status */
      @Output() readonly isFormInvalid: EventEmitter<boolean> = new EventEmitter<boolean>();
  
      /** Emit created form */
      @Output() readonly createdForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  
      /** Main form group */
      customForm: FormGroup;
  
      formConfig: Partial<FormInputs> = {};
  
      private destroy$ = {
          updateFormEvent: new Subject<void>(),
          serverErrorEvent: new Subject<void>(),
      };
  
      private serverErrorIntoDFA: { [dfaControlName: string]: { controlPath: string[] } } = {};
  
      constructor(private errorService: OcErrorService, private formConfigService: FormConfigService, public router : Router) {
         

      }
  
      ngOnInit(): void {
          this.setFormConfig();
          this.generateForm();
          this.listenServerErrors();
      }
  
      ngOnChanges(changes: SimpleChanges): void {
          if (changes.formJsonData && changes.formJsonData.previousValue !== changes.formJsonData.currentValue) {
              this.generateForm();
          }
      }
  
      ngOnDestroy(): void {
          Object.values(this.destroy$).forEach(destroy => {
              destroy.next();
              destroy.complete();
          });
      }
  
      /**
       * Generating form by JSON data
       */
      generateForm(): void {
          this.customForm = null;
          if (this.generatedForm) {
              this.customForm = this.generatedForm;
          } else if (this.formJsonData.fields) {
              this.customForm = new FormGroup(CustomOcFormGenerator.getFormByConfig(this.formJsonData.fields, defaultFieldsForTrim));
          }
          if (this.setFormDirty) {
              this.setDirty();
          }
          this.initDFAPathsByControl();
          this.createdForm.emit(this.customForm);
          if (!this.showButton) {
              this.subscribeToForm();
          }
      }
  
      /**
       * Output event which returns form value
       */
      sendData(): void {
          if (!this.anotherInvalidResult && !this.process) {
              // normalize object hierarchy by dots.
              // Like : { customData.text: value } => {customData: {text: value}}
              let formData: any = {};
              forIn(this.customForm.getRawValue() || {}, (value, key) => (formData = set(formData, key, value)));
  
              // trim required text fields
              formData = TrimTextUtils.trimTextFields(formData, this.formJsonData?.fields, defaultFieldsForTrim);
  
              if (this.customForm.valid && this.showButton) {
                  this.formSubmitted.emit(formData);
              } else {
                  this.formDataUpdated.emit(formData);
              }
          }
      }
  
      /**
       * Emit cancel submit
       */
      cancelForm(): void {
          this.cancelSubmit.emit(true);
      }
  
      /**
       * Check and get placeholder value
       */
      checkAndGetPlaceholderValue(placeholder: string, formElement: any): string {
          if (placeholder || placeholder === '') {
              return placeholder;
          } else if (formElement.placeholder) {
              return formElement.placeholder;
          } else {
              return `Select ${formElement?.label}`;
          }
      }
  
      /**
       * Listening to value changes of the form if buttons not applied
       */
      subscribeToForm(): void {
          this.isFormInvalid.emit(this.customForm.invalid);
          this.sendData();
  
          this.customForm.valueChanges.pipe(takeUntil(this.destroy$.updateFormEvent)).subscribe(() => {
              this.isFormInvalid.emit(this.customForm.invalid);
              this.sendData();
          });
      }
  
      /**
       * Callback function for trackBy logic
       */
      trackByFieldId(index: number, formElement: any): string {
          return `${formElement.id}`;
      }
  
      /**
       * Set all controls as touched and dirty
       */
      private setDirty(): void {
          (Object as any).values(this.customForm.controls).forEach(control => {
              control.markAsTouched();
              control.markAsDirty();
          });
      }
  
      private setFormConfig(): void {
          this.formConfig = this.formConfigService.config[this.formId] || this.formConfigService.config.default;
      }
  
      private listenServerErrors(): void {
          this.errorService.serverErrorEvent
              .pipe(
                  map(() => this.errorService.serverErrorList || []),
                  takeUntil(this.destroy$.serverErrorEvent),
              )
              .subscribe(errors => this.updateDFAErrors(errors));
      }
  
      private updateDFAErrors(errors: any[]): void {
          for (const controlName of Object.keys(this.serverErrorIntoDFA)) {
              const hasDfaError = this.hasDfaError(errors, this.serverErrorIntoDFA[controlName].controlPath);
  
              const dfaControl = this.customForm.controls[controlName];
  
              if (hasDfaError) {
                  // set new DFA error
                  dfaControl.setErrors({
                      ...(dfaControl.errors || {}),
                      ...CustomOcFormValidator.createChildDfaFieldError(this.formJsonData?.fields?.find(field => field.id === controlName)),
                  });
              } else if (dfaControl.errors?.invalidDFAField && dfaControl.valid) {
                  // remove DFA error only when: DFA without server and field errors.
                  const newErrors = { ...dfaControl.errors };
                  delete newErrors.invalidDFAField;
                  dfaControl.setErrors(newErrors);
              }
          }
      }
  
      private initDFAPathsByControl(): void {
          this.serverErrorIntoDFA = {};
          if (this.formJsonData?.fields) {
              this.formJsonData.fields.forEach(field => {
                  if (field && field.id && field.type === 'dynamicFieldArray') {
                      this.serverErrorIntoDFA[field.id] = {
                          controlPath: toPath(ControlUtils.getFullControlPath(this.customForm.controls[field.id])),
                      };
                  }
              });
          }
      }
  
      private hasDfaError(errors: any[], dfaPath: string[]): boolean {
          return !!errors.find(error => {
              const errorPath = toPath(error?.field || '');
              return dfaPath.filter((path, i) => path !== errorPath[i]).length === 0;
          });
      }
  }
  
