import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OcCommonLibModule, OcFormComponentsModule} from '@mbd-common-libs/angular-common-components';
import {CamelCaseToNormalPipe} from './pipes/camel-case-to-normal.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppConfirmationModalComponent} from './modals/app-confirmation-modal/app-confirmation-modal.component';
import {PermissionDirective} from './directive/permission.directive';
import { ScrollspyDirective } from './directive/scrollspy.directive';
import { CustomOcFormComponent } from './components/custom-oc-form/custom-oc-form.component';
import { CustomOcTooltipLabelComponent } from './components/custom-oc-tooltip-label/custom-oc-tooltip-label.component';
import { CustomOcSingleFormComponent } from './components/custom-oc-single-form/custom-oc-single-form.component';
import { ClipboardModule } from 'ngx-clipboard';
import { CustomFormComponent } from './components/custom-form/custom-form.component';
import { CustomInviteModalComponent } from './modals/custom-invite-modal/custom-invite-modal.component';
import { CustomOcSelectComponent } from './components/custom-oc-select/custom-oc-select.component';

@NgModule({
  declarations: [
    AppConfirmationModalComponent,
    CamelCaseToNormalPipe,
    PermissionDirective,
    ScrollspyDirective,
    CustomFormComponent,
    CustomOcFormComponent,
    CustomOcTooltipLabelComponent,
    CustomOcSingleFormComponent,
    CustomInviteModalComponent,
    CustomOcSelectComponent
  ],
  imports: [
    CommonModule,
    OcCommonLibModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    OcFormComponentsModule,
    ClipboardModule
  ],
  exports: [
    OcCommonLibModule,
    CamelCaseToNormalPipe,
    FormsModule,
    ReactiveFormsModule,
    PermissionDirective,
    ScrollspyDirective,
    CustomFormComponent,
    CustomOcFormComponent,
    CustomOcTooltipLabelComponent,
    CustomOcSingleFormComponent,
    CustomInviteModalComponent
  ]
})
export class SharedModule { }
