<div class="bg-container bg-a py-4">
    <div class="container d-flex flex-column align-items-center justify-content-center">
        <h1 class="mt-2 mb-0 text-center">
            Reach millions of developers through <br> the Marketplace
        </h1>
        <p class="my-2 text-secondary text-center">
            A design template for implementing your app store with Gorgias
        </p>
        <oc-button type="primary" text="Become a Partner" [class]="'mb-5'">
        </oc-button>
    </div>
</div>
<div class="container d-flex justify-content-between container-padding flex-column flex-md-row">
    <section class="col-md-5 mb-4 mb-md-0">
        <h4>Why list on the Gorgias Marketplace?</h4>
        <p class="text-secondary">
            Build traction for your open source project or software
            company by listing your 1-Click Application in the Gorgias Marketplace.
        </p>
        <img src="../../../assets/img/temporary_home_pic.png" alt="" class="img-fluid article-image">
    </section>
    <div class="col-md-5 mt-3 mt-md-0">
        <div class="feature-card">
            <div class="row mx-0 align-items-center flex-nowrap">
                <div class="feature-icon">
                    <img src="../../../assets/img/app-icon1.svg" alt="">
                </div>
                <p class="section-title">Reach more users</p>
            </div>
            <p class="text-secondary mt-2 mb-0">
                Gorgias global community of 4 million developers relies heavily on open source
                and free software when getting started. The Gorgias Marketplace helps them easily
                find and launch your project or product.
            </p>
        </div>
        <div class="feature-card">
            <div class="row mx-0 align-items-center flex-nowrap">
                <div class="feature-icon">
                    <img src="../../../assets/img/icon-placeholder.svg" alt="">
                </div>
                <p class="section-title">Create new revenue streams</p>
            </div>
            <p class="text-secondary mt-2 mb-0">
                You can sell licenses directly to users now and, we plan to make new billing
                options available to help you create new and easier revenue streams for your business or projects.
            </p>
        </div>
        <div class="feature-card">
            <div class="row mx-0 align-items-center flex-nowrap">
                <div class="feature-icon">
                    <img src="../../../assets/img/icon-placeholder2.svg" alt="">
                </div>
                <p class="section-title">Find new committers</p>
            </div>
            <p class="text-secondary mt-2 mb-0">
                Putting your 1-Click App in the DigitalOcean Marketplace introduces your
                project to more developers, which can lead to more people supporting and committing
                to it down the road.
            </p>
        </div>
    </div>
</div>
<div class="bg-container bg-a py-5">
    <div class="container d-flex flex-column align-items-center justify-content-center mt-4">
        <h4 class="mt-2 mb-0 text-center mb-3">
            How developers are using the Gorgias <br> Marketplace?
        </h4>
        <div class="row mx-0">
            <div class="technology-card p-2">
                <div class="row mx-0 align-items-center">
                    <div class="technology-icon">
                        <img src="../../../assets/img/app-icon1.svg" alt="">
                    </div>
                    <p class="section-title">Plesk</p>
                </div>
                <p class="text-secondary mt-2 mb-1 technology-description">
                    Plesk's free 1-Click Application enabled the company
                    to capture thousands of new users in record time.
                </p>
                <a class="cursor-pointer">
                    <span class="text-primary font-m font-med pr-1">See Case</span>
                    <img alt="icon" src="./assets/img/arrow-right.svg">
                </a>
            </div>
            <div class="technology-card p-2">
                <div class="row mx-0 align-items-center">
                    <div class="technology-icon">
                        <img src="../../../assets/img/app-icon.svg" alt="">
                    </div>
                    <p class="section-title">Hasura GraphQL</p>
                </div>
                <p class="text-secondary mt-2 mb-1 technology-description">
                    As a new startup, Hasura enjoys greater exposure and
                    faster user adoption for their GraphQL engine, which brings blazing fast…
                </p>
                <a class="cursor-pointer">
                    <span class="text-primary font-m font-med pr-1">See Case</span>
                    <img alt="icon" src="./assets/img/arrow-right.svg">
                </a>
            </div>
            <div class="technology-card p-2">
                <div class="row mx-0 align-items-center">
                    <div class="technology-icon">
                        <img src="../../../assets/img/app-icon3.svg" alt="">
                    </div>
                    <p class="section-title">Zulip</p>
                </div>
                <p class="text-secondary mt-2 mb-1 technology-description">
                    Zulip users can get an open source chat server
                    online in just minutes using the Gorgias Marketplace.
                </p>
                <a class="cursor-pointer">
                    <span class="text-primary font-m font-med pr-1">See Case</span>
                    <img alt="icon" src="./assets/img/arrow-right.svg">
                </a>
            </div>
        </div>
    </div>
</div>
<div class="container container-padding d-flex flex-column">
    <h4 class="col-md-5 mb-4">Why list on the Gorgias Marketplace?</h4>
    <div class="row mx-0 mb-2 justify-content-between">
        <div class="col-md-5 mb-3 mb-md-0">
            <div class="row mx-0 align-items-center flex-nowrap">
                <span class="list-number">
                    1
                </span>
                <p class="section-title">Register to become a Marketplace Vendor</p>
            </div>
            <p class="text-secondary mt-2 mb-0">
                Just click <a href="" class="link font-med">Become a Seller</a> and give us a few pieces
                of information about your 1-Click
                App idea and your company. We'll respond with next steps or additional questions.
            </p>
        </div>
        <div class="col-md-5 mb-3 mb-md-0">
            <div class="row mx-0 align-items-center flex-nowrap">
                <span class="list-number">
                    2
                </span>
                <p class="section-title">Build your image</p>
            </div>
            <p class="text-secondary mt-2 mb-0">
                Quickly and simply build a VM image on Gorgias manually,
                or automate via Fabric and Packer, <a href="" class="link font-med">
                then check its quality and readiness with our tools.</a>
                Or you can simply build a Kubernetes app with a single
                <a href="" class="link font-med">PR on our repo.</a>
            </p>
        </div>
    </div>
    <div class="row mx-0 justify-content-between">
        <div class="col-md-5 mb-3 mb-md-0">
            <div class="row mx-0 align-items-center flex-nowrap">
                <span class="list-number">
                    3
                </span>
                <p class="section-title">Give us the details about your listing</p>
            </div>
            <p class="text-secondary mt-2 mb-0">
                Provide the details and your 1-Click Apps on Droplet VMs and Gorgias
                Kubernetes will then be made available to all Gorgias customers once approved.
                (We plan to support listings of SaaS applications soon, too.)
            </p>
        </div>
        <div class="col-md-5 mb-3 mb-md-0">
            <div class="row mx-0 align-items-center flex-nowrap">
                <span class="list-number">
                    4
                </span>
                <p class="section-title">Engage with the community</p>
            </div>
            <p class="text-secondary mt-2 mb-0">
                We encourage you to connect with your users by sharing links to
                forums or other support channels within your Marketplace listing.
                Take advantage of the opportunity to hear what your users want out of your
                product or project. And don't forget to send users to your new 1-Click App listing.
            </p>
        </div>
    </div>
</div>
<div class="bg-container bg-a py-5">
    <div class="container">
        <oc-app-get-started getStartedDescription="Register as an app developer
                    and submit your app easily with our App Store Developer Portal"
                            getStartedButtonText="Get Started as an App Developer"
                            getStartedImage="./assets/img/get-started.svg"
                            (getStarted)="getStartedRedirect()">
        </oc-app-get-started>
    </div>
</div>

