import { MetaTagsPageConfig, SiteConfig } from '@mbd-common-libs/angular-common-services';

export const metaTags: MetaTagsPageConfig = {
  defaultMetaTags: [
    // { name: 'author', content: 'OpenChannel' },
    // { name: 'generator', content: 'OpenChannel' },
    // { name: 'og:url', definitionPath: 'windowUrl' },
],
  pages: [
    {
        routerLinkStartsWith: '',
        metaTags: [
            { name: 'description', content: '', definitionPath: 'defDescription' },
            { name: 'og:title', content: '', definitionPath: 'defTitle' },
            { name: 'og:image', content: '', definitionPath: 'defOgimg' },
            //   Facebook Meta Tags 
            { name: 'og:url', content: window.location.href, definitionPath: 'defOgimg' },
            { property:'og:type', content:'website'},
            { property: 'og:title', content: '', definitionPath: 'defTitle' },
            { property: 'og:description', content: '', definitionPath: 'defDescription' },
            { property: 'og:image', content: '', definitionPath: 'defTitle' },
            //   Twitter Meta Tags 
            { name: 'twitter:card', content: 'summary_large_image', definitionPath: '' },
            { property: 'twitter:domain', content: window.location.href, definitionPath: 'app.customData.summary' },
            { property: 'twitter:url', content: window.location.href, definitionPath: 'app.customData.summary' },
            { name: 'twitter:title', content: '', definitionPath: 'defTitle' },
            { name: 'twitter:description', content: '', definitionPath: 'defDescription' },
            { name: 'twitter:image', content: '', definitionPath: 'defOgimg' }, 
        ],

    }
],

};

export const pageConfig = {
  "listing-actions": [
    {
      "type": "form",
      "appTypes": ["gorgias-apps"],
      "formId": "support-form",
      "message": {
        "success": "Thank you for submitting, we'll get back to you shortly",
        "fail": "Due to an error, we were not able to send your form"
      },
      "button": {
        "class": "oc-button_secondary",
        "text": "Contact"
      }
    }
  ]
}

export const siteConfig: SiteConfig = {
  title: '',
  tagline: 'All the apps and integrations that you need',
  metaTags: [],
  favicon: {
      href: 'assets/img/favicon.png',
      type: 'image/x-icon',
  },
};
