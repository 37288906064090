<div class="form-steps">
    <ng-container *ngIf="hasFieldGroup; else singleForm">
        <app-custom-oc-tooltip-label *ngIf="showGroupHeading"
                          [text]="stepLabel"
                          class="form-steps__label"
                          [required]="currentForm.label?.attributes?.required"
                          [description]="currentForm.label?.description"></app-custom-oc-tooltip-label>
        <app-custom-oc-single-form [formJsonData]="currentForm.formConfig"
                        [generatedForm]="currentForm"
                        [showButton]="false"
                        [labelPosition]="labelPosition"
                        [appId]="appId"
                        [pageType]='pageType'
                        (formDataUpdated)="onFormDataUpdated($event)"></app-custom-oc-single-form>
    </ng-container>
    <div class="form-steps__options form-steps__options--{{buttonPosition}}"
         *ngIf="showButton">
        <oc-button *ngIf="isFirstStep"
                   class="form__button"
                   type="secondary"
                   text="Cancel"
                   (click)="cancelSubmit.emit()"></oc-button>
        <ng-container *ngIf="additionalButton"
                      [ngTemplateOutlet]="additionalButton"></ng-container>
        <oc-button *ngIf="showSubmitButton && pageType==='update'"
                   (click)="onSubmitButtonClicked()"
                   [process]="process"
                   class="form__button"
                   type="primary"
                   [text]="successButtonText"></oc-button>
    </div>
</div>

<ng-template #singleForm>
    <app-custom-oc-single-form [formJsonData]="formJsonData"
                    [generatedForm]="generatedForm"
                    [labelPosition]="labelPosition"
                    [process]="process"
                    [showButton]="false"
                    [showGroupHeading]="showGroupHeading"
                    [pageType]='pageType'
                    [appId]="appId"
                    displayType="page"
                    (isFormInvalid)="isFormInvalid.emit($event)"
                    (formDataUpdated)="formDataUpdated.emit($event)"
                    (createdForm)="createdForm.emit($event)"></app-custom-oc-single-form>
</ng-template>