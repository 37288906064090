import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {first, takeUntil} from 'rxjs/operators';
import {AuthenticationService, SiteConfigService, TitleService} from '@mbd-common-libs/angular-common-services';
import {Subject} from 'rxjs';
import {LoadingBarState} from '@ngx-loading-bar/core/loading-bar.state';
import {LoadingBarService} from '@ngx-loading-bar/core';
import { siteConfig } from '../assets/data/siteConfig';
import { PortalMetaTagService } from '@core/services/meta-tag-service/meta-tag-service.service';
import { CmsContentService } from '@core/services/cms-content-service/cms-content-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private destroy$: Subject<void> = new Subject();
  private loader: LoadingBarState;

  constructor(private router: Router,
              private authApiService: AuthenticationService,
              private siteService: SiteConfigService,
              public loadingBar: LoadingBarService,
              private titleService: TitleService,
              private metaTagService: PortalMetaTagService,
              private cmsService: CmsContentService,) {

    this.loader = this.loadingBar.useRef();
  }

  ngOnInit() {
    this.siteService.initSiteConfiguration(siteConfig);
    // refresh JWT token if exists
    this.loader.start();
    this.authApiService.tryLoginByRefreshToken()
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => this.loader.stop(), () => this.loader.stop());

    // init csrf
    this.authApiService.initCsrf()
    .pipe(takeUntil(this.destroy$), first())
    .subscribe(() => {
    });

    this.cmsService.getcustomContentFromAPI('opengraph-meta-tag').subscribe(res => {
      // console.log(res,'res');
      this.metaTagService.pushSelectedFieldsToTempPageData({
          defTitle: res[0].customData.title ? res[0].customData.title : 'Gorgias Portal ',
          defOgimg: res[0].customData.image ? res[0].customData.image : 'assets/img/default-OG.png',
          defDescription: res[0].customData.description
              ? res[0].customData.description
              : 'The #1 load board for growing your business. Gorgias is the freight community’s most trusted partner in freight rates, data, negotiation tools, and load board',
              defkeywords :  res[0].customData.keywords.length!==0 ? Object.keys(res[0].customData.keywords).map(function(k){return res[0].customData.keywords[k]}).join(", ") : 'Gorgias, market ',
      });
  });

  }
}
