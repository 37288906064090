<div class="select-component" ngbDropdown #myDrop="ngbDropdown"
     [class.select-component_disabled]="disabled">
    <oc-button
        class="select-component__action"
        type="secondary"
        [customTemplate]="selectButton"
        [disabled]="disabled"
        (blur)="onblur()"
        ngbDropdownToggle></oc-button>
    <div class="select-component__dropdown" ngbDropdownMenu aria-labelledby="dropdownManual">
        <button class="select-component__dropdown-item" ngbDropdownItem (click)="onSelectionChange(val)"
                *ngFor="let val of selectValArr">
            {{ labelField ? val[labelField] : val.value | transformText: transformText}}
        </button>
    </div>
</div>

<ng-template #selectButton>
    <div class="select-component__text">{{ inputLabel | transformText: transformText}}</div>
</ng-template>