<div class="invite-modal">
    <div class="invite-modal__header header">
        <h3 class="invite-modal__header-heading">{{ this.modalData.modalTitle }}</h3>
        <svg-icon
            class="invite-modal__header-close-icon"
            src="assets/angular-common-components/close-icon.svg"
            (click)="dismiss()"></svg-icon>
    </div>
    <div modalContent class="invite-modal__modal-body">
        <app-custom-form
            [formJsonData]="formConfig"
            [showButton]="false"
            [formId]="formId"
            (createdForm)="setCreatedForm($event)"
            (formDataUpdated)="setDataFromForm($event)"></app-custom-form>
        <div class="invite-modal__button-container">
            <oc-button
                type="secondary"
                text="Cancel"
                (click)="dismiss()"
                class="invite-modal__button invite-modal__dismiss-button"></oc-button>
            <oc-button
                type="primary"
                class="invite-modal__button invite-modal__confirm-button"
                [text]="this.modalData.successButtonText"
                [process]="inProcess"
                (click)="onClickConfirmButton()"></oc-button>
        </div>
    </div>
</div>