<div class="main-container d-flex flex-column">
    <header>
        <div class="container">
            <img src="assets/img/logo-company-dark.png" class="logo" alt="logo">
        </div>
    </header>
    <div class="container mt-0 d-flex flex-column align-items-center justify-content-center">
        <img class="not-found-img w-100 mb-5" src="assets/img/not_found.svg" alt="not-found">
        <h1 class="mt-3 mb-3">Page not found</h1>
        <span class="subtitle mb-4">The page you were looking for isn’t here</span>
        <oc-button type="primary" text="Go to home page" class="button-255 mb-4"
                   (click)="goToHomePage()"></oc-button>
    </div>
</div>
