import { Component, OnInit } from '@angular/core';
import { AuthHolderService } from '@mbd-common-libs/angular-common-services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-new',
  templateUrl: './home-new.component.html',
  styleUrls: ['./home-new.component.scss']
})
export class HomeNewComponent implements OnInit {

  constructor(private authService: AuthHolderService,
    private router: Router) { }

  ngOnInit() {
    if (this.authService.isLoggedInUser()) {
      this.router.navigate(['/app/manage']);
    }
    else {
      this.router.navigate(['/signup']);
    }
  }

}
