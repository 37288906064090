<div class="tooltip-label">
    <label class="tooltip-label__text" [ngClass]="labelClass">{{text}}
        <span *ngIf="required" class="tooltip-label__required">*</span>
    </label>
    <ng-container *ngIf="type==='fieldGroup' || pageType !== 'update'">
            <div *ngIf="description"
         ngbTooltip="{{description}}"
         placement="right"
         container="body" class="tooltip-label__description">
        <svg-icon [src]="infoTitleIconCsv" svgClass="tooltip-label__description-icon"></svg-icon>
    </div>

    </ng-container>
</div>
<ng-container *ngIf="type!=='fieldGroup' && pageType === 'update'">
    <div *ngIf="description" class="custom-tooltip-label__description">
    {{description}}
</div>
</ng-container>