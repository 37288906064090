import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import {
  AppFormModel,
  OcFormGenerator,
} from "@mbd-common-libs/angular-common-components";
import { ClipboardService } from "ngx-clipboard";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-custom-oc-single-form",
  templateUrl: "./custom-oc-single-form.component.html",
  styleUrls: ["./custom-oc-single-form.component.scss"],
})
export class CustomOcSingleFormComponent implements OnInit {
  constructor(
    private clipboardService: ClipboardService,
    private toaster: ToastrService
  ) {}

  /**
   * JSON with all form data to generate dynamic form
   */
  @Input() formJsonData: AppFormModel;

  /**
   * Set disable for button
   * when siblings form is invalid
   */
  @Input() anotherInvalidResult = false;

  /**
   * Show button on form.
   * @default true
   */
  @Input() showButton: boolean = true;

  /**
   * Set position of the buttons.
   * Can be: "center", "left", "right".
   * @default 'left'
   */
  @Input() buttonPosition: "center" | "left" | "right" = "left";

  /**
   * Set custom text to success button.
   * @default 'Submit'
   */
  @Input() successButtonText: string = "Submit";

  /**
   * Set position of the field label. Can be: "top", "left", "right".
   * @default 'top'
   */
  @Input() labelPosition: "top" | "left" | "right" = "top";

  /**
   * Set form "dirty" after form init
   */
  @Input() setFormDirty: boolean = false;

  /**
   * Submitting process. `true` option will lock for
   *  click and start the spinner in the submit button
   */
  @Input() process: boolean = false;

  /**
   * Flag to show group heading
   */
  @Input() showGroupHeading: boolean = true;

  /**
   * Already generated Form Group
   */
  @Input() generatedForm: FormGroup;
  @Input() pageType;
  @Input() appId;

  /**
   * Returning all form fields value to the parent component
   */
  @Output() readonly formSubmitted = new EventEmitter<any>();

  /** Sending true when user cancel form submitting */
  @Output() readonly cancelSubmit: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  /** When need to get data of the form without buttons */
  @Output() readonly formDataUpdated: EventEmitter<any> =
    new EventEmitter<any>();

  /** Send form valid status */
  @Output() readonly isFormInvalid: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  /** Emit created form */
  @Output() readonly createdForm: EventEmitter<FormGroup> =
    new EventEmitter<FormGroup>();

  /** Main form group */
  customForm: FormGroup;

  /** Result data from form for submission */
  formData: any;

  customefild: any;

  missingCatagory = [];

  supportedEcommercePlatforms = [];

  categoryerror: boolean = false;

  supportedEcommerceerror: boolean = false;

  catagoryError :string;
  supportError : string;

  /** @private Subscription for a main form */
  private formSubscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.generateForm();

    //custome message logic of catagory.
    let listOfcatagory = [];
    this.formJsonData.fields.filter((el) => {
      if (el.id == "category") {
        listOfcatagory = el.options;
      }
    });

    this.customForm.get("category").value.filter((el) => {
      if (!listOfcatagory.includes(el)) {
        this.missingCatagory.push(el);
      }
      return false;
    });

    if (this.missingCatagory.length > 0) {
         this.categoryerror = true;
        if(this.missingCatagory.length == 1){
            let catagoryErrorMessage = this.missingCatagory.join(', ').replace(/, ([^,]*)$/, ' and $1')
            this.catagoryError = catagoryErrorMessage + " is an invalid value in Categories";
        }
        if(this.missingCatagory.length != 0 && this.missingCatagory.length != 1){
            let catagoryErrorMessage = this.missingCatagory.join(', ').replace(/, ([^,]*)$/, ' and $1')
            this.catagoryError = catagoryErrorMessage + " are invalid value in Categories";
        }
    }

    this.customForm.get("category").valueChanges.subscribe((res) => {
      let tempArray = [];
      this.missingCatagory.forEach((element) => {
        let index = res.indexOf(element);
        console.log("index: ", index);
        if (index >= 0) {
          tempArray.push(element);
        }
      });
      this.missingCatagory = tempArray;
      if (this.missingCatagory.length > 0) {
        this.categoryerror = true;
        if(this.missingCatagory.length == 1){
            let catagoryErrorMessage = this.missingCatagory.join(', ').replace(/, ([^,]*)$/, ' and $1')
            this.catagoryError = catagoryErrorMessage + " is an invalid value in Categories";
        }
        if(this.missingCatagory.length != 0 && this.missingCatagory.length != 1){
            let catagoryErrorMessage = this.missingCatagory.join(', ').replace(/, ([^,]*)$/, ' and $1')
            this.catagoryError = catagoryErrorMessage + " are invalid value in Categories";
        }
        
      }else{
        this.categoryerror = false;
      }
    });

    //custome message logic of supported-ecommerce-platforms.
    let supportedEcommercePlatformsList = [];
    this.formJsonData.fields.filter((el) => {
      if (el.id == "supported-ecommerce-platforms") {
        supportedEcommercePlatformsList = el.options;
      }
    });

    this.customForm.get("supported-ecommerce-platforms").value.filter((el) => {
        if (!supportedEcommercePlatformsList.includes(el)) {
          this.supportedEcommercePlatforms.push(el);
        }
        return false;
      });
  
      if (this.supportedEcommercePlatforms.length > 0) {
        // this.supportErrorMessage =  this.supportedEcommercePlatforms.join(', ').replace(/, ([^,]*)$/, ' and $1')
        this.supportedEcommerceerror = true;

        if(this.supportedEcommercePlatforms.length == 1){
            let supportErrorMessage =  this.supportedEcommercePlatforms.join(', ').replace(/, ([^,]*)$/, ' and $1')
            this.supportError = supportErrorMessage + " is an invalid value in Support eCommerce Platforms";
        }
        if(this.supportedEcommercePlatforms.length != 0 && this.supportedEcommercePlatforms.length != 1){
            let supportErrorMessage =  this.supportedEcommercePlatforms.join(', ').replace(/, ([^,]*)$/, ' and $1')
            this.supportError = supportErrorMessage + " are invalid value in Support eCommerce Platforms";
        }
      }

      this.customForm.get("supported-ecommerce-platforms").valueChanges.subscribe((res) => {
        let tempArray = [];
        this.supportedEcommercePlatforms.forEach((element) => {
          let index = res.indexOf(element);
          console.log("index: ", index);
          if (index >= 0) {
            tempArray.push(element);
          }
        });
        
        this.supportedEcommercePlatforms = tempArray;
        if (this.supportedEcommercePlatforms.length > 0) {
            this.supportedEcommerceerror = true;
            if(this.supportedEcommercePlatforms.length == 1){
                let supportErrorMessage = this.supportedEcommercePlatforms.join(', ').replace(/, ([^,]*)$/, ' and $1')
                this.supportError = supportErrorMessage + " is an invalid value in Support eCommerce Platforms";
            }
            if(this.supportedEcommercePlatforms.length != 0 && this.supportedEcommercePlatforms.length != 1){
                 let supportErrorMessage = this.supportedEcommercePlatforms.join(', ').replace(/, ([^,]*)$/, ' and $1')
                this.supportError = supportErrorMessage + " are invalid value in Support eCommerce Platforms";
            }
        }else{
          this.supportedEcommerceerror = false;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.formJsonData &&
      changes.formJsonData.previousValue !== changes.formJsonData.currentValue
    ) {
      this.generateForm();
    }
  }

  ngOnDestroy(): void {
    if (!this.showButton) {
      this.formSubscription.unsubscribe();
    }
  }

  /**
   * Replace all dots in form json data
   */
  removeJSONDots(): void {
    this.formJsonData.fields.forEach((field) => {
      field.id = field.id.replace(".", "/");
    });
  }

  /**
   * Generating form by JSON data
   */
  generateForm(): void {
    if (this.generatedForm) {
      this.customForm = this.generatedForm;
    } else if (this.formJsonData.fields) {
      this.removeJSONDots();
      this.customForm = new FormGroup(
        OcFormGenerator.getFormByConfig(this.formJsonData.fields)
      );
    }
    if (this.setFormDirty) {
      this.setDirty();
    }
    this.createdForm.emit(this.customForm);
    if (!this.showButton) {
      this.subscribeToForm();
    }
  }

  /**
   * Output event which returns form value
   */
  sendData(): void {
    if (!this.anotherInvalidResult && !this.process) {
      const formData = this.customForm.getRawValue();
      Object.keys(formData).forEach((key) => {
        if (key.includes("/")) {
          formData[key.replace("/", ".")] = formData[key];
          delete formData[key];
        }
      });
      if (this.customForm.valid && this.showButton) {
        this.formSubmitted.emit(formData);
      } else {
        this.formDataUpdated.emit(formData);
      }
    }
  }

  /**
   * Emit cancel submit
   */
  cancelForm(): void {
    this.cancelSubmit.emit(true);
  }

  /**
   * Listening to value changes of the form if buttons not applied
   */
  subscribeToForm(): void {
    this.isFormInvalid.emit(this.customForm.invalid);
    this.sendData();

    this.formSubscription.add(
      this.customForm.valueChanges.subscribe(() => {
        this.isFormInvalid.emit(this.customForm.invalid);
        this.sendData();
      })
    );
  }

  /**
   * Callback function for trackBy logic
   */
  trackByFieldId(index: number, formElement: any): string {
    return `${formElement.id}`;
  }

  /**
   * Check if DFA control is invalid and if yes return an error
   * @param {AbstractControl} dfaControl - control to check
   * @param {string} label - label for control
   * @returns `string`
   */
  getDfaError(dfaControl: AbstractControl, label: string): string {
    return dfaControl.touched && dfaControl.invalid
      ? "Please, check all fields inside " + label
      : "";
  }

  /**
   * Set all controls as touched and dirty
   */
  private setDirty(): void {
    (Object as any).values(this.customForm.controls).forEach((control) => {
      control.markAsTouched();
      control.markAsDirty();
    });
  }

  copyContentToClipBoard(copyData) {
    this.clipboardService.copyFromContent(copyData);
    this.toaster.show("copied content");
  }
}
