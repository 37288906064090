import { Directive, Input, EventEmitter, Output, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[scrollSpy]'
})
export class ScrollspyDirective {

    @Input() public spiedTags = [];
    @Input() public searchTagsfrom;
    @Output() public sectionChange = new EventEmitter<string>();
    private currentSection: string;

    constructor(private _el: ElementRef) { }

    @HostListener('window:scroll', ['$event']) onScroll(event: any) {
        let currentSection: string;
        const children = document.querySelectorAll(this.searchTagsfrom);
        const scrollTop = event.target.scrollingElement.scrollTop;
        const parentOffset = event.target.scrollingElement.offsetTop;

        for (let i = 0; i < children.length; i++) {
            const element: any = children[i];
            if (this.spiedTags.some(spiedTag => spiedTag === element.tagName)) {
                if ((element.offsetTop - parentOffset) <= scrollTop + 10) {
                    currentSection = element.innerText;
                }
            }
        }
        if (currentSection !== this.currentSection) {
            this.currentSection = currentSection;
            this.sectionChange.emit(this.currentSection);
        }
    }

}
