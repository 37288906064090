import { Injectable } from '@angular/core';
import { CMSSiteContentService, SiteContentService } from '@mbd-common-libs/angular-common-services';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { of } from 'rxjs';
import defaultCMSData from '../../../../../_defaultContent.json';

@Injectable({
    providedIn: 'root',
})
export class  CmsContentService extends CMSSiteContentService {
    readonly defaultCMSType = 'site';
    constructor(private siteContentService: SiteContentService) {
        super();
        super.initContent();
    }

    /**
     * Getting content from openchannel API.
     */

    getcustomContentFromAPI(provenpath?): Observable<any> {
        if (environment.enableCMS) {
           
                if(provenpath){
                    return this.siteContentService
                    .getAllContent(1, 100, null, `{'type':'${provenpath}'}`)
                    .pipe(map(r => r.list));
                }
                else{
                    return this.siteContentService
                    .getAllContent(1, 1, null, `{'type':'${this.defaultCMSType}'}`)
                    .pipe(map(r => r.list[0]?.customData));
                }
        }
       else {
            return of();
        }
    }

    getContentFromAPI(): Observable<any> {
        if (environment.enableCMS) {
            return this.siteContentService
                .getAllContent(1, 1, null, `{'type':'${this.defaultCMSType}'}`)
                .pipe(map(r => r.list[0]?.customData));
        } else {
            return of();
        }
    }

    getContentDefault(): any {
        return defaultCMSData;
    }
}
