import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpConfigInterceptor} from '@core/interceptors/httpconfig.interceptor';
import {CustomHttpClientXsrfModule, OcCommonServiceModule} from '@mbd-common-libs/angular-common-services';
import {OAuthModule} from 'angular-oauth2-oidc';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DragDropModule} from '@angular/cdk/drag-drop';
// tslint:disable-next-line:max-line-length
import {ToastrModule} from 'ngx-toastr';
import {TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import {HttpErrorInterceptor} from '@core/interceptors/httperror.interceptor';
import {SharedModule} from '@shared/shared.module';
import {HomeComponent} from './pages/home/home.component';
import {HomeNewComponent} from './pages/home-new/home-new.component';
import {environment} from '@env';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import { FileService } from '@core/services/file.service';
import { FileUploaderService } from '@mbd-common-libs/angular-common-components';
import {
  AbstractErrorMessageConfiguration,
  DefaultErrorMessageConfiguration,
} from '@mbd-common-libs/angular-common-components/src/lib/common-components';

const apiURl = environment.enableProxy ? `${window.origin}/client-api/` : environment.apiUrl;

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    HomeNewComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  imports: [
    HttpClientModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    OcCommonServiceModule.forRoot(apiURl),
    DragDropModule,
    OAuthModule.forRoot(),
    ToastrModule.forRoot(),
    CustomHttpClientXsrfModule.withOptions({headerName: 'X-CSRF-TOKEN', apiUrl: apiURl}),
    SharedModule,
    LoadingBarModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'},
    { provide: FileUploaderService, useClass: FileService },
    { provide: AbstractErrorMessageConfiguration, useValue: new DefaultErrorMessageConfiguration() },

  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {

}
