<form class="form" [formGroup]="customForm" *ngIf="customForm">
    <ng-container *ngFor="let formElement of formJsonData?.fields; trackBy:trackByFieldId">
       <ng-container *ngIf="(formElement?.id !== 'client-id' && formElement?.id !== 'client-secret');else anotherFileds">
        <div *ngIf="formElement.type !== 'fieldGroup' || showGroupHeading" class="form__field" [ngClass]="(labelPosition === 'right') ?
        'form__field_direction_row_reverse'
        : (labelPosition === 'left') ? 'form__field_direction_row_normal' : ''">
           <app-custom-oc-tooltip-label [text]="formElement?.label" class="form__field-label"
                             [required]="formElement?.attributes?.required"
                             [pageType] = 'pageType'
                             [ngClass]="{ 'form__field-label_position_left form__field-label_row': labelPosition === 'left',
                'form__field-label_position_right form__field-label_row': labelPosition === 'right',
                'form__group-heading': formElement.type === 'fieldGroup'}"
                             [description]="formElement?.description ? formElement?.description : ''"
                             [type]="formElement?.type ? formElement?.type : ''"></app-custom-oc-tooltip-label>
           <div class="form__field-input">
               <div [ngSwitch]="formElement?.type">
                   <oc-rich-text-editor *ngSwitchCase="'richText'"
                                        formControlName="{{formElement?.id}}"
                                        [placeholder]="formElement?.placeholder"></oc-rich-text-editor>
                  <ng-container *ngSwitchCase="'text'">
                   <oc-input
                   *ngIf="(formElement?.id !== 'client-id' && formElement?.id !== 'client-secret')"
                             formControlName="{{formElement?.id}}"
                             [placeholder]="formElement?.placeholder"></oc-input>
                  </ng-container>
                   <oc-textarea *ngSwitchCase="'longText'"
                                formControlName="{{formElement?.id}}"
                                [placeholder]="formElement?.placeholder"></oc-textarea>
                   <ng-container *ngSwitchCase="'dropdownList'">
                       <ng-container [ngSwitch]="formElement?.attributes?.subType">
                           <oc-radio-button-list *ngSwitchCase="'radioButton'"
                                                 [formControlName]="formElement?.id"
                                                 [itemsArray]="formElement?.options"
                                                 [radioButtonGroup]="formElement?.id"></oc-radio-button-list>
                           <oc-select *ngSwitchDefault
                                      [formControlName]="formElement?.id"
                                      [selectValArr]="formElement?.options"></oc-select>
                       </ng-container>
                   </ng-container>

                   <oc-file-upload *ngSwitchCase="'singleFile'"
                                   formControlName="{{formElement?.id}}"
                                   isMultiFile="true"
                                   acceptType="{{formElement?.attributes?.accept}}"
                                   fileType="singleFile"></oc-file-upload>
                   <oc-file-upload *ngSwitchCase="'multiFile'"
                                   formControlName="{{formElement?.id}}"
                                   isMultiFile="true"
                                   acceptType="{{formElement?.attributes?.accept}}"
                                   fileType="multiFile"></oc-file-upload>
                   <oc-file-upload *ngSwitchCase="'multiImage'"
                                   formControlName="{{formElement?.id}}"
                                   isMultiFile="true"
                                   fileType="multiImage"
                                   acceptType="{{formElement?.attributes?.accept}}"
                                   imageWidth="{{formElement?.attributes?.width}}"
                                   imageHeight="{{formElement?.attributes?.height}}"></oc-file-upload>
                   <oc-file-upload *ngSwitchCase="'singleImage'"
                                   formControlName="{{formElement?.id}}"
                                   fileType="singleImage"
                                   acceptType="{{formElement?.attributes?.accept}}"
                                   imageWidth="{{formElement?.attributes?.width}}"
                                   imageHeight="{{formElement?.attributes?.height}}">
                   </oc-file-upload>
                   <oc-file-upload *ngSwitchCase="'privateSingleFile'"
                                   formControlName="{{formElement?.id}}"
                                   acceptType="{{formElement?.attributes?.accept}}"
                                   fileType="privateSingleFile">
                   </oc-file-upload>
                   <oc-file-upload *ngSwitchCase="'multiPrivateFile'"
                                   formControlName="{{formElement?.id}}"
                                   acceptType="{{formElement?.attributes?.accept}}"
                                   fileType="multiPrivateFile">
                   </oc-file-upload>
                   <oc-number *ngSwitchCase="'number'"
                              formControlName="{{formElement?.id}}"
                              [placeholder]="formElement?.placeholder"></oc-number>
                   <oc-checkbox *ngSwitchCase="'checkbox'"
                                formControlName="{{formElement?.id}}"
                                [labelText]="formElement?.description"
                                [requiredIndicator]="formElement?.attributes.required"></oc-checkbox>
                   <oc-input *ngSwitchCase="'emailAddress'"
                             formControlName="{{formElement?.id}}"
                             inputType="email"
                             [placeholder]="formElement?.placeholder ? formElement.placeholder : 'myemail@example.com'"></oc-input>
                   <oc-input *ngSwitchCase="'websiteUrl'"
                             formControlName="{{formElement?.id}}" inputType="url"
                             [placeholder]="formElement?.placeholder ? formElement.placeholder : 'https://my.website.com'"></oc-input>
                   <oc-color *ngSwitchCase="'color'"
                             formControlName="{{formElement?.id}}"
                             [placeholder]="formElement?.placeholder"></oc-color>
                   <oc-tags *ngSwitchCase="'tags'"
                            formControlName="{{formElement?.id}}"
                            [placeholder]="formElement.placeholder"
                            [availableTags]="formElement?.options ? formElement?.options : ['']"></oc-tags>
                   <oc-tags *ngSwitchCase="'booleanTags'"
                            formControlName="{{formElement?.id}}"
                            [placeholder]="formElement.placeholder"
                            [availableTags]="formElement?.options ? formElement?.options : ['']"
                            tagsType="boolean"></oc-tags>
                   <oc-tags *ngSwitchCase="'numberTags'"
                            formControlName="{{formElement?.id}}"
                            [placeholder]="formElement.placeholder"
                            [availableTags]="formElement?.options ? formElement?.options : ['']"
                            tagsType="number"></oc-tags>
                   <oc-video-url *ngSwitchCase="'videoUrl'"
                                 [formControlName]="formElement?.id"
                                 [placeholder]="formElement?.placeholder ? formElement.placeholder : 'https://my.website.com'"></oc-video-url>
                   <oc-datetime-picker *ngSwitchCase="'date'"
                                       formControlName="{{formElement?.id}}"
                                       [type]="formElement?.type"
                                       [settings]="{format: 'dd/MM/yyyy'}"></oc-datetime-picker>
                   <oc-datetime-picker *ngSwitchCase="'datetime'"
                                       formControlName="{{formElement?.id}}"
                                       [type]="formElement?.type"></oc-datetime-picker>
                   <oc-dropdown-multi-app *ngSwitchCase="'multiApp'"
                                          formControlName="{{formElement?.id}}"
                                          [defaultAppIDs]="formElement?.options"></oc-dropdown-multi-app>
                   <ng-container *ngSwitchCase="'multiselectList'">

                       <ng-container [ngSwitch]="formElement?.attributes?.subType">

                           <oc-multi-select-checkbox-list *ngSwitchCase="'checkbox'"
                                                          formControlName="{{formElement?.id}}"
                                                          [itemsArray]="formElement?.options"
                                                          [defaultItemsArray]="formElement.defaultValue"></oc-multi-select-checkbox-list>
                            
                           <!-- <oc-multi-select-list *ngSwitchDefault
                                                 formControlName="{{formElement?.id}}"
                                                 [availableItemsList]="formElement?.options ? formElement?.options : ['']"
                                                labelText="close"
                                                 [label]="formElement?.label"></oc-multi-select-list> -->
                                <oc-multi-select-list
                                                 *ngSwitchDefault
                                                 formControlName="{{formElement?.id}}"
                                                 [tagTooltipLabelText]="formElement.id == 'category' ? 'Close' : ''"
                                                 [availableItemsList]="formElement?.options ? formElement?.options : ['']"
                                                 [label]="formElement?.label"></oc-multi-select-list>                               
                                    
                                <div class="error">
                                    <p class="error__feedback" *ngIf="supportedEcommerceerror && formElement?.id == 'supported-ecommerce-platforms'">{{supportError}}</p>
                                </div>
                                <div class="error">
                                    <p class="error__feedback" *ngIf="categoryerror && formElement?.id == 'category'">{{catagoryError}}</p>
                                </div>
                            </ng-container>
                   </ng-container>
                   <oc-dynamic-field-array *ngSwitchCase="'dynamicFieldArray'"
                                           [fieldDefinitionData]="formElement"
                                           [dfaFormArray]="customForm.get(formElement.id)"></oc-dynamic-field-array>
                   <p *ngSwitchCase="'dynamicFieldArray'" class="form__field-custom-error">
                       {{getDfaError(customForm.get(formElement.id), formElement.label)}}
                   </p>
                   <oc-password *ngSwitchCase="'password'"
                                formControlName="{{formElement?.id}}"
                                [placeholder]="formElement?.placeholder"></oc-password>
               </div>
               <oc-error [field]="formElement?.id"
                         [control]="customForm.get(formElement?.id)"></oc-error>
                         
                
           </div>
       </div>
       </ng-container>
       <ng-template #anotherFileds>
        <div *ngIf="(formElement.type !== 'fieldGroup' && pageType=='update')  || (showGroupHeading && pageType=='update')" class="form__field" [ngClass]="(labelPosition === 'right') ?
        'form__field_direction_row_reverse'
        : (labelPosition === 'left') ? 'form__field_direction_row_normal' : ''">
        <app-custom-oc-tooltip-label [text]="formElement?.label" class="form__field-label"
                          [required]="formElement?.attributes?.required"
                          [pageType]='pageType'
                          [ngClass]="{ 'form__field-label_position_left form__field-label_row': labelPosition === 'left',
             'form__field-label_position_right form__field-label_row': labelPosition === 'right',
             'form__group-heading': formElement.type === 'fieldGroup'}"
                          [description]="formElement?.description ? formElement?.description : ''"
                          [type]="formElement?.type ? formElement?.type : ''"></app-custom-oc-tooltip-label>

        <div class="app-credentials w-100">
            <div class="auth-fields">
                <div class="form__field form__field_direction_row_normal" *ngIf="pageType && (formElement?.id === 'client-id')">
                     <oc-input formControlName="{{formElement?.id}}" [placeholder]="formElement?.placeholder" ngDefaultControl
                      required="true" ocWhiteSpaceValidator [disabled]=true class="w-100"></oc-input>
                      <div>
                        <button ngxClipboard [cbContent]="'Content to be copied.'" (click)="copyContentToClipBoard(appId)" [title]="'Copy to clipboard'" class="clip-board">
                            <img src="../../../../assets/img/copycliipboard.png">
                        </button>
                      </div>
                  </div>
                  <div class="form__field form__field_direction_row_normal" *ngIf="pageType && (formElement?.id === 'client-secret')">
                    <oc-password  formControlName="{{formElement?.id}}"
                    [placeholder]="formElement?.placeholder" ngDefaultControl  class="w-100" [disabled]=true></oc-password>
                      <div>
                        <button ngxClipboard [cbContent]="'Content to be copied.'" (click)="copyContentToClipBoard(formElement?.defaultValue)" [title]="'Copy to clipboard'" class="clip-board">
                            <img src="../../../../assets/img/copycliipboard.png">
                        </button>
                      </div>
                  </div>
            </div>
        </div>
        </div>
       </ng-template>
    </ng-container>
    <div class="form__buttons"
         *ngIf="showButton"
         [ngClass]="buttonPosition === 'center' ? 'form__buttons_justify_center'
       : buttonPosition === 'left' ? 'form__buttons_justify_start'
       : 'form__buttons_justify_start form__buttons_direction_row_reverse'">
        <oc-button class="form__button"
                   (click)="sendData()"
                   [process]="process"
                   [text]="successButtonText"
                   type="primary"
                   id="successBtn"></oc-button>
        <oc-button class="form__button"
                   (click)="cancelForm()"
                   text="Cancel"
                   type="secondary"></oc-button>
    </div>
</form>